import React, { useState } from "react"
import styled from "styled-components"
import _ from "lodash"
import Image from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const videos = _.chain(data)
    .get("allContentfulVideos.edges", [])
    .map(({ node }) => node)
    .sortBy(["order"])
    .value()
  return (
    <Layout>
      <SEO title="Projects | Clapesque" />
      <VideosList>
        {videos.map((data, index) => (
          <VideoItem key={`video.${index}`} data={data} />
        ))}
      </VideosList>
    </Layout>
  )
}

export default IndexPage

/*
  query ProjectsQuery($locale: String) {
    allContentfulVideos(filter: { node_locale: { eq: $locale } }) {
*/
export const query = graphql`
  query ProjectsQuery {
    allContentfulVideos {
      edges {
        node {
          id
          title
          duration
          url
          order
          picture {
            sizes(maxWidth: 800) {
              ...GatsbyContentfulSizes
            }
          }
        }
      }
    }
  }
`

const VideosList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  a {
    text-decoration: none;
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const VideoItem = ({ data }) => {
  const { title, picture, duration, url } = data
  const [hover, setHover] = useState(false)
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <VideoItemContainer
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Image alt={title} sizes={picture?.sizes} />
        {hover && (
          <VideoItemBackdrop>
            <div>{title}</div>
            <div>{duration}</div>
          </VideoItemBackdrop>
        )}
      </VideoItemContainer>
    </a>
  )
}
const VideoItemContainer = styled.div`
  color: black;
  text-decoration: none;
  transition: all 100ms ease-in;
  position: relative;
`
const VideoItemBackdrop = styled.div`
  background-color: rgba(255, 255, 255, 1);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
